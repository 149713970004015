<template>
  <!-- select 2 demo -->
  <div>
    <b-modal
      ref="modal-save-lisanssifrebilgi"
      title="Lisans Şifre Bilgi Güncelle"
      cancel-variant="outline-secondary"
      no-close-on-backdrop
      centered
      size="lg"
      @hidden="hiddenModal"
      @ok="handleOk"
    >
      <b-form
        ref="form"
        @submit.stop.prevent="saveLisansSifreBilgi">
        <b-row>
          <b-col>
            <b-form-group :label="$t('Lisans Bitiş Tarihi')">
              <b-form-input type="date" v-model="lisansSifreBilgiData.dtLisansBitisTarihi" />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group :label="$t('Uzak IP')">
              <b-form-input disabled minlength="7" maxlength="15" size="15" type="text" v-model="lisansSifreBilgiData.sRemoteIp" />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group :label="$t('Şifre')">
              <b-form-input type="text" maxlength="50" v-model="lisansSifreBilgiData.sSifreInfo" />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="6" md="6" sm="12">
            <b-form-group :label="$t('Lisans No')">
              <b-form-input disabled type="text" maxlength="50" v-model="lisansSifreBilgiData.sLisansNo" />
            </b-form-group>
          </b-col>
           <b-col>
            <b-form-group :label="$t('Makine Kodu')">
              <b-form-input disabled type="text" maxlength="50" v-model="lisansSifreBilgiData.sRemoteBilgisayarAdi" />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
      <template #modal-footer="{ ok, cancel }">
      <!-- Emulate built in modal footer ok and cancel button actions -->
      <b-button @click="ok()" variant="primary">{{ $t('Kaydet') }} </b-button>
      <b-button @click="deleteLicenseAuth" variant="danger">{{ $t('Yeni Kurulum Ayarla') }} </b-button>
      <!-- Button with custom close trigger value -->
      <b-button @click="cancel()" class="float-left" variant="outline-secondary">{{ $t('Kapat') }} </b-button>
    </template>
    </b-modal>
  </div>
</template>

<script>
import { apiToDate } from '@core/utils/filter';
import AlertService from '@/common/alert.service'

// Toast
import ToastificationSuccess from '@core/components/toastification/ToastificationSuccess.vue'

export default {
  props: {
    modalObject: {
      type: Object,
       default() {
        return {}
      },
    },
    callback: {
      type: Function,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      lisansSifreBilgiData: {
        sRemoteBilgisayarAdi: null,
        sRemoteIp: null,
        sSifreInfo: null,
        dtLisansBitisTarihi: null,
      },
    }
  },
  methods: {
    saveLisansSifreBilgi() {
      this.$store.dispatch('lisanssifrebilgiYonetim/updateLisansSifreBilgi', this.lisansSifreBilgiData, this)
        .then(response => {
          if (response.statusCode === 200) {
            this.$toast({
              component: ToastificationSuccess,
              position: 'top-right',
              props: { text: response.message },
            })
            this.modalObject.onSuccess();
            this.hiddenModal()
          } else {
            AlertService.error(this, response.message)
          }
        });
    },

    resetForm() {
      this.lisansSifreBilgiData.sRemoteBilgisayarAdi = null;
      this.lisansSifreBilgiData.sRemoteIp = null;
      this.lisansSifreBilgiData.sSifreInfo = null;
    },

    deleteLicenseAuth() {
      AlertService.confirmDelete(this, success => {
        this.$store.dispatch('lisanssifrebilgiYonetim/deleteLicenseAuth', this.lisansSifreBilgiData.sLisansNo)
        .then(response => {
        if (response.statusCode === 200) {
          AlertService.success(this, response.message)
        } else {
          AlertService.error(this, response.message)
        }
        });
      });
    },

    initialModal() {
      this.$store.dispatch('lisanssifrebilgiYonetim/fetchLisansSifreBilgi', this.modalObject.id)
      .then(response => {
        if (response.statusCode === 200) {
          this.lisansSifreBilgiData = response.result;
          this.lisansSifreBilgiData.dtLisansBitisTarihi = apiToDate(response.result.dtLisansBitisTarihi);
          this.$refs['modal-save-lisanssifrebilgi'].show();
        } else {
          AlertService.error(this, response.message)
        }
      });
    },
    
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.saveLisansSifreBilgi()
    },

    hiddenModal() {
      this.$refs['modal-save-lisanssifrebilgi'].hide()
      this.modalObject.showModal = false;
    },
  },
  mounted() {
    this.initialModal()
  },
};
</script>
