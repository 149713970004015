<template>
  <div>

    <!-- Buttons -->
    <crud-button :showAddNew="false" :onClickList="fetchLisansSifreBilgis" />

    <!-- Filters -->
    <lisans-sifre-bilgi-filters />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ $t('tane göster') }}</label>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="lisansSifreBilgisTable"
        class="position-relative"
        :items="getLisansSifreBilgis"
        :per-page="perPage"
        :current-page="currentPage"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="Hiçbir eşleşen kayıt bulunamadı"
        :sort-desc.sync="isSortDirDesc">
      
      <template #cell(musteri)="data">
            <b-link @click="data.toggleDetails" class="font-weight-bold d-block text-nowrap">
              {{ data.item.musteri === "" || data.item.musteri === null ? 'BELİRTİLMEMİŞ' : data.item.musteri }}
            </b-link>
      </template>
      <template #cell(active)="data">
          {{ data.item.users.filter(x => x.isLisansGecerli).length }}
      </template>
      <template #cell(inactive)="data">
          {{ data.item.users.filter(x => !x.isLisansGecerli).length }}
      </template>
      <template #cell(length)="data">
        {{ data.item.users.length }}
      </template>
      <template #row-details="data">
        <b-table
        class="position-relative"
        :items="data.item.users"
        per-page="10"
        :current-page="data.item.currentPage"
        responsive
        :fields="detailTableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="Hiçbir eşleşen kayıt bulunamadı"
        :sort-desc.sync="isSortDirDesc">
       
        <!-- Column: Kullanici -->
        <template #cell(kullanici)="detailRow">
          <div class="text-nowrap">
            <span class="align-text-top">{{ detailRow.item.kullanici }}</span>
          </div>
        </template>
        <template #cell(urun)="detailRow">
          <div class="text-nowrap">
            <span class="align-text-top">{{ detailRow.item.urun }}</span>
          </div>
        </template>

          <template #cell(sLisansNo)="detailRow">
          <div class="text-nowrap">
            <span class="align-text-top">{{ detailRow.item.sLisansNo }}</span>
          </div>
        </template>

        <!-- Column: Gecerlilik Tarih -->
        <template #cell(dtLisansBitisTarihi)="detailRow">
          <div class="text-nowrap">
            <span class="align-text-top">{{ datetolocale(detailRow.item.dtLisansBitisTarihi) }}</span>
          </div>
        </template>

        <!-- Column: Status -->
        <template #cell(isLisansGecerli)="detailRow">
          <div class="text-nowrap">
            <feather-icon
              :icon="resolveStatusIcon(detailRow.item.isLisansGecerli)"
              size="18"
              class="mr-50"
              :class="`text-${resolveStatusVariant(detailRow.item.isLisansGecerli)}`"
            />
            <span class="align-text-top text-capitalize">{{ resolveStatusName(detailRow.item.isLisansGecerli) }}</span>
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="detailRow">
          <feather-icon
            :id="`user-row-${detailRow.item.id}-edit-icon`"
            @click="onUpdate(detailRow.item.id)"
            icon="EditIcon"
            class="cursor-pointer text-primary"
            size="16"
            />
        </template>
        </b-table>
          <b-col
            cols="12" sm="6">
            <b-pagination
              v-model="data.item.currentPage"
              :total-rows="data.item.users.length"
              per-page="10"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
      </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12" sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="text-muted">{{ dataMeta.of }} öğeden {{ dataMeta.from }} - {{ dataMeta.to }} arası gösteriliyor</span>
          </b-col>

          <!-- Pagination -->
          <b-col
            cols="12" sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end">

            <b-pagination
              v-model="currentPage"
              :total-rows="toplamLisansSifreBilgis"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>

    <save-lisans-sifre-bilgi v-if="modalObject.showModal" :modalObject="modalObject" />
  </div>
</template>

<script>
import { datetolocale } from '@/@core/utils/filter'

import LisansSifreBilgiFilters from './LisansSifreBilgiFilters.vue'
import SaveLisansSifreBilgi from './SaveLisansSifreBilgi.vue'
import useLisansSifreBilgis from './useLisansSifreBilgis'

export default {
  components: {
    LisansSifreBilgiFilters,
    SaveLisansSifreBilgi,
    
  },
  data() {
    return {
      modalObject: {
        id: null,
        showModal: false,
        onSuccess: this.fetchLisansSifreBilgis,
      },
    }
  },
  computed: {
    lisansGecerliFilter() {
      return this.$store.getters['lisanssifrebilgiYonetim/getLisansSifreBilgiFilters'].isLisansGecerli == null;
    },
  },
  methods: {
    datetolocale,
    onUpdate(id) {
      this.modalObject.id = id;
      this.modalObject.showModal = true;
    },
  },
  setup() {
    const {
      fetchLisansSifreBilgis,
      getLisansSifreBilgis,
      tableColumns,
      detailTableColumns,
      perPage,
      currentPage,
      toplamLisansSifreBilgis,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      lisansSifreBilgisTable,

      resolveStatusIcon,
      resolveStatusName,
      resolveStatusVariant,
    } = useLisansSifreBilgis()
  
    return {
      fetchLisansSifreBilgis,
      getLisansSifreBilgis,
      tableColumns,
      detailTableColumns,
      perPage,
      currentPage,
      toplamLisansSifreBilgis,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      lisansSifreBilgisTable,

      resolveStatusIcon,
      resolveStatusName,
      resolveStatusVariant,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
