<template>
  <b-card no-body>
    <b-card-body>
      <b-row>
        <b-col cols="12" md="4" class="mb-md-0 mb-2">
          <b-form-group>
            <label for="dtBaslangicFilter">
              {{ $t("Geçerlilik Başlangıç") }}
            </label>
            <b-form-input
              type="date"
              v-model="dtBaslangicFilter"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12" md="4">
          <b-form-group>
            <label for="dtBitisFilter">
              {{ $t("Geçerlilik Bitiş") }}
            </label>
            <b-form-input type="date" v-model="dtBitisFilter" />
          </b-form-group>
        </b-col>
         <b-col cols="12" md="4" class="mb-md-0 mb-2">
          <label>{{ $t("Durum") }}</label>
          <v-select
            v-model="isLisansGecerliFilter"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="lisansSifreBilgiDurumOptions"
            class="w-100"
            :reduce="(val) => val.value"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="4" class="mb-md-0 mb-2">
          <label>{{ $t("Müşteri") }}</label>
          <musteri-selection :musteri-select.sync="musteriIdFilter" />
        </b-col>

         <b-col cols="12" md="4" class="mb-md-0 mb-2">
          <label>{{ $t("Müşteri Kullanıcı") }}</label>
          <musteri-kullanici-selection :musteri-kullanici-select.sync="kullaniciIdFilter" />
        </b-col>
        <b-col cols="12" md="4" class="mb-md-0 mb-2">
          <label>{{ $t("Arama") }}</label>
          <b-form-input
            v-model="queryFilter"
            class="d-inline-block mr-1"
            :placeholder="$t('Ara...')"
          />
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>

// Options
import StaticOptions from '@/common/options/StaticOptions'
import MusteriSelection from '../selection/MusteriSelection.vue'
import MusteriKullaniciSelection from '../selection/MusteriKullaniciSelection.vue'

export default {
  components: {
    MusteriSelection,
    MusteriKullaniciSelection,
  },
  data() {
    return {
      lisansSifreBilgiDurumOptions: StaticOptions.lisansSifreBilgiDurumOptions,
    }
  },
  computed: {
    dtBaslangicFilter: {
      get() {
        return this.$store.getters['lisanssifrebilgiYonetim/getLisansSifreBilgiFilters'].dtLisansBitisBaslangic
      },
      set(value) {
        this.$store.commit('lisanssifrebilgiYonetim/setLisansSifreBilgiFilters', { key: 'dtLisansBitisBaslangic', value })
      },
    },
    dtBitisFilter: {
      get() {
        return this.$store.getters['lisanssifrebilgiYonetim/getLisansSifreBilgiFilters'].dtLisansBitisBitis
      },
      set(value) {
        this.$store.commit('lisanssifrebilgiYonetim/setLisansSifreBilgiFilters', { key: 'dtLisansBitisBitis', value })
      },
    },

    musteriIdFilter: {
      get() {
        return this.$store.getters['lisanssifrebilgiYonetim/getLisansSifreBilgiFilters'].musteriId
      },
      set(value) {
        this.$store.commit('lisanssifrebilgiYonetim/setLisansSifreBilgiFilters', { key: 'musteriId', value })
      },
    },
    kullaniciIdFilter: {
      get() {
        return this.$store.getters['lisanssifrebilgiYonetim/getLisansSifreBilgiFilters'].kullaniciId
      },
      set(value) {
        this.$store.commit('lisanssifrebilgiYonetim/setLisansSifreBilgiFilters', { key: 'kullaniciId', value })
      },
    },

    isLisansGecerliFilter: {
      get() {
        return this.$store.getters['lisanssifrebilgiYonetim/getLisansSifreBilgiFilters'].isLisansGecerli
      },
      set(value) {
        this.$store.commit('lisanssifrebilgiYonetim/setLisansSifreBilgiFilters', { key: 'isLisansGecerli', value })
      },
    },
    queryFilter: {
      get() {
        return this.$store.getters['lisanssifrebilgiYonetim/getLisansSifreBilgiFilters'].query
      },
      set(value) {
        this.$store.commit('lisanssifrebilgiYonetim/setLisansSifreBilgiFilters', { key: 'query', value })
      },
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
